<template>
<v-card class="display-block" flat>
    <h3>Modify Expense</h3>
    <v-card-text>
        <v-form ref="form" lazy-validation>
            <v-layout wrap>
                <v-flex text-right sm2 xs12 mx-1>
                    <input-date label="Date" v-model="convertedDate"></input-date>
                </v-flex>
                <v-flex sm7 xs12 mx-1>
                    <v-text-field v-model="expense.narration" placeholder="Enter Description" :rules="descRequired" required></v-text-field>
                </v-flex>
                <v-flex sm2 xs12 mx-1>
                    <v-text-field label="Expense Amount" type="number" v-model.number="expense.txAmount" required></v-text-field>
                </v-flex>
            </v-layout>
            <v-flex text-center :class="$vuetify.breakpoint.xsOnly?'mx-2':'mx-6'" xs12>
                <label :style="themeInverted">Mode of payment</label><br>
                <payment-mode @update="updateMode" :mode="expense.mode"></payment-mode>
            </v-flex>
            <v-flex text-right>
                <v-btn class="mr-4" :style="theme" :loading="loading" :disabled="loading" @click="addExpenseDetails()">Save</v-btn>
                <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
            </v-flex>
        </v-form>
    </v-card-text>
</v-card>
</template>

<script>
import moment from 'moment'
import appConstants from '@/utils/appConstants'
import PaymentMode from '@/components/PaymentMode.vue'
export default {
    components: {
        PaymentMode,
    },
    data() {
        return {
            expense: {
                txAmount: 0,
                mode: 'CASH',
                defaultAmountOption: 0,
                narration: "",
                txType: 'DR',
                txDate: new Date()
            },
            loading: false
        }
    },
    mounted() {
        this.initComponent()
    },
    computed: {
        convertedDate: {
            get() {
                return moment(this.expense.txDate).format("YYYY-MM-DD")
            },
            set(newValue) {
                this.expense.txDate = moment(newValue, 'DD/MM/YYYY')
            }
        }
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.expense.vendorId = this.getUserProfile().vendorId
            if (this.id != 0) {
                this.expense = await this.getItem(appConstants.EXPENSE_MANAGER_API + "/" + this.id)
            }
        },
        addExpenseDetails() {
            if (this.$refs.form.validate()) {
                this.loading = true
                if (this.id == 0) {
                    this.saveItem()
                } else {
                    this.updateItem()
                }
            }
        },
        async saveItem() {
            try {
                await this.postItem(appConstants.EXPENSE_MANAGER_API, this.expense)
                this.loading = false
                this.$router.push({ name: "Expenses" })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
                this.loading = false
            }
        },
        async updateItem() {
            try {
                await this.putItem(appConstants.EXPENSE_MANAGER_API + "/" + this.expense._id, this.expense)
                this.$router.push({ name: "Expenses" })
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
                this.loading = false
            }
        },
        updateMode(mode) {
            this.expense.mode = mode
        },
    },
}
</script>

<style scoped>
.border-light {
    border-color: lightgray !important;
    text-transform: capitalize;
}
</style>
